import Swiper from 'swiper/bundle';

const [d] = [document];

export class worksBlock {
    constructor() {
        this.render();
    }

    render() {
        const getArticles = this.fetchArticles({
            content: 'works',
            post_per_page: 10,
        });
        const getCategories = this.fetchCategories('works');
        const dispTags = (tags) => {
            tags = tags.map((t) => `<span class="tag">#${t}</span>`);
            return `<span class="tags">${tags.join('')}</span>`;
        };
        const appendSlide = (item, slider) => {
            slider.appendSlide([
                `
        <div class="block-works-item swiper-slide">
          <a href="${item.href}">
            <div class="block-works-item__img-wrap">
            ${
                item.category.name
                    ? `<p class="cate">${item.category.name}</p>`
                    : ''
            }
              <img src="${item.thumb}" alt="${item.title}">
            </div>
            <div class="block-works-item__body">
              <p class="title">${item.title}</p>
              ${item.keywords ? dispTags(item.keywords) : ''}
            </div>
          </a>
        </div>
      `,
            ]);
        };
        const slider = new Swiper('.works_slide', {
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            speed: 1000,
            loopAdditionalSlides: 1,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.works-next',
                prevEl: '.works-prev',
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1000: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
            },
        });
        slider.removeAllSlides();
        Promise.all([getArticles, getCategories]).then(([articles, cats]) => {
            d.querySelector('.works_slide .swiper-wrapper').innerHTML = '';
            articles.map((article) => {
                article.category = cats.find(
                    (v) => v.id == article.category
                ) || {
                    id: '0',
                    href: '/works/?mode=list&cat=0',
                    name: 'その他',
                    turn: 99,
                };
                appendSlide(article, slider);
            });
            slider.slideToLoop(0);
        });
    }

    fetchCategories(content) {
        return fetch(`/assets/api/getCategories/?content=${content}`)
            .then((r) => r.json())
            .catch((e) => {
                throw new Error(e);
            });
    }

    fetchArticles(opt) {
        return fetch(`/assets/api/getArticles/?${new URLSearchParams(opt)}`)
            .then((r) => r.json())
            .catch((e) => {
                throw new Error(e);
            });
    }
}
