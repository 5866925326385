const textAnimation = () => {
    // 交差を検知したい要素
    const textview = document.querySelectorAll('.js-text');
    // IntersectionObserverのオプションを設定
    const options = {
        root: null,
        rootMargin: '30% 0px -30%',
        threshold: 0, // 閾値は0
    };

    // IntersectionObserverを呼び出し
    const observer = new IntersectionObserver(showElement, options);

    // lazydispクラスが付与されてる要素にたどり着いたらIntersectionObserverを実行
    textview.forEach((elm) => {
        observer.observe(elm);
    });

    // 要素が表示されたら実行する動作
    function showElement(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // 要素にactiveクラスを付与
                entry.target.classList.add('is-active');
            }
        });
    }
};

export default function ABOUT() {
  $(() =>
  {
      Promise.all([window.load['about-01']]).then(textAnimation);
    });
}
