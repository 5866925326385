// import Swiper from 'swiper/bundle';
// import GET_ARTICLES from '../class/getArticles';
import { renovetionBlock } from '../class/renovetion-block';

const [d] = [document];

const renovetion = () => {
    new renovetionBlock();

    //アコーディオン
    document.querySelectorAll('._accordion__btn').forEach(function (el) {
        const wrap = el.closest('._accordion__item'); // c-accordion-wrap要素を取得
        const content = el.nextElementSibling; // c-accordion-content要素
        const contentHeight = content.scrollHeight + 'px'; // コンテンツの高さを計算

        content.style.overflow = 'hidden';
        content.style.transition = '0.5s';
        content.style.height = wrap.classList.contains('is-open')
            ? contentHeight
            : '0'; // 初期状態の高さ設定

        el.onclick = () => {
            const isOpen = wrap.classList.toggle('is-open'); // is-openクラスのトグル
            content.style.height = isOpen ? contentHeight : '0'; // 高さの設定

            // 高さが動的に変わる可能性があるため、再計算して設定
            if (isOpen) {
                // 開く操作後、実際の高さに基づいて再設定
                window.setTimeout(() => {
                    content.style.height = content.scrollHeight + 'px';
                }, 10);
            }
        };
    });
};
export default function RENOVETION() {
    $(() => {
        Promise.all([window.load['renovetion-01']]).then(renovetion);
    });
}
