import TAGSORT,{taglist} from "../class/tagsort";

const [d] = [document];

const itemTemplate =  `
  <div class="works-list" :class="[ 'is-paged-' + paged ]">
    <div class="works-list__inner">
      <div class="works-item" v-for="(article , ind) in pagedArticles">
        <a :href="article.href">
          <div class="works-item__img-wrap">
            <p class="cate" v-if="article.category.name">{{ article.category.name }}</p>
            <img :src="article.thumb" :alt="article.title" class="works-item__img" width="256" height="256">
          </div>
          <div class="works-item__body">
            <h3 class="title">{{ article.title }}</h3>
            <span class="tags" v-if="article.keywords.length">
              <span class="tag" v-for="(tag, ind) in article.keywords">#{{ tag }}</span>
            </span>
          </div>
          <!-- /.works-item__body -->
        </a>
        <!-- /.works-item-->
      </div>
    </div>
  </div>
  `;

const relatedItemTemplate =  `
  <div class="works-list is-related" v-if="relatedArticles.length">
    <h3 class="works-list__title">関連事例</h3>
    <div class="works-list__inner works-item-wrap">
      <div class="works-item-wrap" v-for="(article , ind) in relatedArticles">
        <a :href="article.href" class="works-item">
          <div class="works-item__img-wrap">
            <p class="cate" v-if="article.category.name">{{ article.category.name }}</p>
            <img :src="article.thumb" :alt="article.title" class="works-item__img" width="256" height="256">
          </div>
          <div class="works-item__body">
            <h3 class="title">{{ article.title }}</h3>
            <span class="tags" v-if="article.keywords.length">
              <span class="tag" v-for="(tag, ind) in article.keywords">#{{ tag }}</span>
            </span>
          </div>
          <!-- /.works-item__body -->
        </a>
        <!-- /.works-item-->
      </div>
    </div>
  </div>
  `;


class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    const is_list = d.body.classList.contains('is--list');
    const is_detail = d.body.classList.contains('is--detail');
    if( !container ) return false;

    // top
    if( is_list ){
      container.insertAdjacentHTML('beforebegin', tag);
    }

    // bottom
    if( pagination && is_detail ){
      pagination.insertAdjacentHTML('afterend', tag);
    }

  }
}

const domReady = () => {

  const Tagsort = new TAGSORT({
    list: {
      itemTemplate,
      perpage: 9, // 一度に表示する記事数
    },
    taglist: {
      pagination: '.rwd002-pagination', // taglistを表示する基準の要素
    },
    related: {
      container: '.rwd002-pagination',
      itemTemplate: relatedItemTemplate
    }
  });

  d.addEventListener('tagsort.beforeInit', e => {
    const TAGSORT = e.detail;
    Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
  });

  Tagsort.render();
};

export default function WORKS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}