import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";
import { worksBlock } from "../class/works-block";

const [d,mq] = [document,window.matchMedia( "(max-width: 1023px)" ) ];

class Blocks
{
  constructor()
  {
    this.init();
    new worksBlock;
  }

  init()
  {
    this.headline();
    this.news('.block-news__list[data-plugin="getArticles"]');
  }

  mainvisual() {
    //メインスライダー
    new Swiper('.mainvisual-slider[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      allowTouchMove: false,
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 10000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mainvisual__slider-pager",
        clickable: true,
      },
    });
  }

  //headline
  headline() {
    const getArticle = new GET_ARTICLES('.mainvisual-headline__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item, content) => `
      <a href="${item.href}" class="mainvisual-headline__link">
        <div class="date-cate">
          <span class="date">${item.date}</span><span class="cate">${item.category.name}</span>
        </div>
        <h3 class="subject ${item.is_new ? 'is-new' : ''}">${item.title.str_truncate(25)}</h3>
      </a>
      `;
    getArticle.render();
  }

  news(selector)
  {
    const news_block = new GET_ARTICLES(selector);
    const getCategories = this.fetchCategories('news');
    const categoryContainer = d.querySelectorAll('.block-news [data-plugin="getCategories"]');
    news_block.makeItem = (item,content) => `
    <li class="block-${content}__item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}__item__link">
        <div class="block-${content}__item-img">
          <img src="${item.thumb}" alt="${item.title}">
        </div>
        <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        <div class="date-cate">
          <span class="date">${item.date}</span><span class="cate">${item.category.name}</span>
        </div>
      </a>
    </li>
    `;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();

    getCategories.then((cats) => {
      cats = [...cats];
      const makeHTML = (cat) => `<div class="block-news__categories-item is-${cat.id}"><a href="${cat.href}">${cat.name}</a></div>`;
      categoryContainer.forEach((obj) => {
        obj.innerHTML = '';
        cats.forEach((cat) =>
          obj.insertAdjacentHTML('beforeend', makeHTML(cat))
        );
      });
    });
  }
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

// instaAPI
async function instaAPI() {
  // 子要素<ul>を追加
  // document.querySelector('#insta').insertAdjacentHTML('beforeend', '<ul></ul>');

  const cards = 7; // insta投稿の表示件数を指定
  const businessId = '17841467076422223';
  const token = 'EAAHltb9yPYQBOzOjNy27jmMV4oy5NA61Q27ywntu0KwQGuOaAeN7BujGZAt16ADC1GrwumMZB5YjxJhvpxIqDRNw2pi2bnfa4pmo7wEQHsF4JdEGPYV5ZAkEJnGurGp8dr01bpd3EMwtnUvb9g0TeUOgsdB0MEjwjCZCseoIieBPGb1hytoYNfwuCv7WnAZBoxMKsr6r2';
  const response = await fetch(
    `https://graph.facebook.com/v19.0/${businessId}?fields=name,media.limit(${cards}){ caption,media_url,thumbnail_url,permalink,like_count,comments_count,media_type}&access_token=${token}`
  );

  if (response.status === 200) {
    const resObjects = await response.json();
    console.log(resObjects);
    //（挙動への影響は一切無いものの）オブジェクト{resObjects.media}内のプロパティ{paging}のせいで「instaItems[1]が無いというエラー」が出るので削除して以降の処理を進めていく
    delete resObjects.media.paging;

    Object.entries(resObjects.media).forEach((instaItems) => {
      // console.log(instaItems);
      instaItems[1].forEach((eachItem) => {
        if (eachItem.media_url !== null) {
          // 投稿が動画か否かを判定して{media}を変更
          if (eachItem.media_type === 'VIDEO') {
            eachItem.media = eachItem.thumbnail_url;
          } else {
            eachItem.media = eachItem.media_url;
          }

          document
            .querySelector('#insta')
            .insertAdjacentHTML(
              'beforeend',
              `<div class="instafeed__item"><a href="${eachItem.permalink}" target="_blank" rel="noopener"><img src="${eachItem.media}"></a></div>`
            );
        }
      });
    });
  } else {
    document
      .querySelector('#insta')
      .insertAdjacentHTML(
        'beforeend',
        `<p style="text-align:center;width:100%;">読み込めませんでした</p>`
      );
  }
}

const domReady = TPL =>
{
  let blocks = new Blocks;
  window.addEventListener('load', () => {
    blocks.mainvisual();
  });

  instaAPI(); // 関数の実行
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}